import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { Language } from './shared/enums/language.enum';
import { AuthGuard } from './shared/guards/auth.guard';
import { LanguageParamGuard } from './shared/guards/language-param.guard';

const routes: Routes = [
  {
    matcher: (url: UrlSegment[]): UrlMatchResult | null => {
      if (url[0]?.path && Object.values(Language).includes(url[0].path as Language)) {
        return {
          consumed: [url[0]],
          posParams: {
            languageId: url[0],
          },
        };
      }

      return {
        consumed: [],
      };
    },
    canActivate: [LanguageParamGuard],
    children: [
      {
        path: 'pages',
        canActivate: [AuthGuard],
        // tslint:disable-next-line: typedef
        loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
      },
      {
        path: 'auth',
        canActivate: [],
        // tslint:disable-next-line: typedef
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
      },
      { path: '', redirectTo: 'pages', pathMatch: 'full' },
      { path: '**', redirectTo: 'pages' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [LanguageParamGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}

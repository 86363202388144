import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { LanguageService } from '../services/language.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authService: NbAuthService,
    private readonly router: Router,
    private readonly languageService: LanguageService
  ) {}

  public canActivate(): any {
    return this.authService.isAuthenticatedOrRefresh().pipe(
      tap((authenticated: boolean) => {
        if (!authenticated) {
          this.router.navigate([this.languageService.prefixUrlWithLanguage('auth/login')]);
        }
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Language } from '../enums/language.enum';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly activeLanguageSubject$ = new BehaviorSubject<Language>(Language.HU);

  public readonly language$ = this.activeLanguageSubject$.asObservable().pipe(distinctUntilChanged());

  public get language(): Language {
    return this.activeLanguageSubject$.getValue();
  }

  public setLanguage(language: Language): void {
    this.activeLanguageSubject$.next(language);
  }

  public prefixUrlSegmentsWithLanguage(segments: string[]): string[] {
    if (Object.values(Language).includes(segments[0] as Language)) {
      return segments;
    }

    const newSegments: string[] = [this.language];
    segments.forEach((segment: string) => newSegments.push(segment));
    return newSegments;
  }

  public prefixUrlWithLanguage(url: string): string {
    if (url.startsWith(`/${this.language}`) || url.startsWith(this.language)) {
      return url;
    }

    if (url.startsWith('/')) {
      return `/${this.language}${url}`;
    }

    return `${this.language}/${url}`;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ApiModule as EgisAdminApiModule,
  BASE_PATH as EGIS_ADMIN_BASE_PATH,
  Configuration as EgisAdminConfiguration,
  ConfigurationParameters as EgisAdminConfigurationParameters,
} from '@egis/admin';
import {
  ApiModule as AdminApiModule,
  BASE_PATH as ADMIN_BASE_PATH,
  Configuration as AdminConfiguration,
  ConfigurationParameters as AdminConfigurationParameters,
} from '@launchpad/admin';
import {
  ApiModule as TransactionApiModule,
  BASE_PATH as TRANSACTION_BASE_PATH,
  Configuration as TransactionConfiguration,
  ConfigurationParameters as TransactionConfigurationParameters,
} from '@launchpad/channeltransaction';
import {
  ApiModule as DeviceApiModule,
  BASE_PATH as DEVICE_BASE_PATH,
  Configuration as DeviceConfiguration,
  ConfigurationParameters as DeviceConfigurationParameters,
} from '@launchpad/device';
import {
  ApiModule as OauthApiModule,
  BASE_PATH as OAUTH_BASE_PATH,
  Configuration as OauthConfiguration,
  ConfigurationParameters as OauthConfigurationParameters,
} from '@launchpad/oauth';
import {
  ApiModule as UserApiModule,
  BASE_PATH as USER_BASE_PATH,
  Configuration as UserConfiguration,
  ConfigurationParameters as UserConfigurationParameters,
} from '@launchpad/user';
import { CONFIG_TOKEN } from 'config-token';
import { Config } from '../config';

// Function expressions are not supported in decorators
// tslint:disable-next-line: only-arrow-functions
export function egisApiAdminConfigFactory(): EgisAdminConfiguration {
  const params: EgisAdminConfigurationParameters = {};
  return new EgisAdminConfiguration(params);
}
// Function expressions are not supported in decorators
// tslint:disable-next-line: only-arrow-functions
export function apiAdminConfigFactory(): AdminConfiguration {
  const params: AdminConfigurationParameters = {};
  return new AdminConfiguration(params);
}
// tslint:disable-next-line: only-arrow-functions
export function apiDeviceConfigFactory(): DeviceConfiguration {
  const params: DeviceConfigurationParameters = {};
  return new DeviceConfiguration(params);
}
// tslint:disable-next-line: only-arrow-functions
export function apiTransactionConfigFactory(): TransactionConfiguration {
  const params: TransactionConfigurationParameters = {};
  return new TransactionConfiguration(params);
}
// tslint:disable-next-line: only-arrow-functions
export function apiUserConfigFactory(): UserConfiguration {
  const params: UserConfigurationParameters = {};
  return new UserConfiguration(params);
}
// tslint:disable-next-line: only-arrow-functions
export function apiOauthConfigFactory(): OauthConfiguration {
  const params: OauthConfigurationParameters = {};
  return new OauthConfiguration(params);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EgisAdminApiModule.forRoot(egisApiAdminConfigFactory),
    AdminApiModule.forRoot(apiAdminConfigFactory),
    UserApiModule.forRoot(apiUserConfigFactory),
    DeviceApiModule.forRoot(apiDeviceConfigFactory),
    TransactionApiModule.forRoot(apiTransactionConfigFactory),
    OauthApiModule.forRoot(apiOauthConfigFactory),
  ],
  providers: [
    {
      provide: EGIS_ADMIN_BASE_PATH,
      useFactory: (config: Config): string => {
        return `${config.backendUrl}/backend`;
      },
      deps: [CONFIG_TOKEN],
    },
    {
      provide: ADMIN_BASE_PATH,
      useFactory: (config: Config): string => {
        return `${config.backendUrl}/admin-service`;
      },
      deps: [CONFIG_TOKEN],
    },
    {
      provide: DEVICE_BASE_PATH,
      useFactory: (config: Config): string => {
        return `${config.backendUrl}/device-management`;
      },
      deps: [CONFIG_TOKEN],
    },
    {
      provide: TRANSACTION_BASE_PATH,
      useFactory: (config: Config): string => {
        return `${config.backendUrl}/channel-transactions`;
      },
      deps: [CONFIG_TOKEN],
    },
    {
      provide: USER_BASE_PATH,
      useFactory: (config: Config): string => {
        return `${config.backendUrl}/users`;
      },
      deps: [CONFIG_TOKEN],
    },
    {
      provide: OAUTH_BASE_PATH,
      useFactory: (config: Config): string => {
        return `${config.backendUrl}/oauth`;
      },
      deps: [CONFIG_TOKEN],
    },
  ],
})
export class ApiModule {}

import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/shared/services/language.service';

@Injectable()
export class CountryHeaderInterceptor implements HttpInterceptor {
  constructor(private readonly languageService: LanguageService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(this.setXCountryHeader(req));
  }

  private setXCountryHeader(req: HttpRequest<any>): HttpRequest<any> {
    const currentLanguage = this.languageService.language;
    return req.clone({
      headers: req.headers.set('X-Country', currentLanguage.toUpperCase()),
    });
  }
}

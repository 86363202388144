import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Language } from '../enums/language.enum';
import { LanguageService } from '../services/language.service';

@Injectable()
export class LanguageParamGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly location: Location, private readonly languageService: LanguageService) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const unverifiedLanguageId = route.paramMap?.get?.('languageId') ?? '';
    let verifiedLanguageId = '';

    const matchingLang = Object.values(Language).find((lang: Language) => {
      return lang === unverifiedLanguageId || unverifiedLanguageId.startsWith(lang);
    });

    if (matchingLang) {
      verifiedLanguageId = matchingLang;
    } else {
      verifiedLanguageId = this.languageService.language;
    }

    this.languageService.setLanguage(verifiedLanguageId as Language);

    if (unverifiedLanguageId === verifiedLanguageId) {
      return true;
    }

    let pathName = this.location.path();

    pathName = `/${verifiedLanguageId}${pathName}`;

    return this.router.createUrlTree([pathName]);
  }
}
